import { initializeApp } from "firebase/app"
import { getAnalytics, isSupported } from "firebase/analytics"

const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: "nameless-948a8.firebaseapp.com",
    projectId: "nameless-948a8",
    storageBucket: "nameless-948a8.appspot.com",
    messagingSenderId: "526708433703",
    appId: "1:526708433703:web:dbe1ccc77ac5c4f319bf02",
    measurementId: "G-2BLZ3CWGK0"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const fire = {
    app,
    analytics: isSupported().then(supported => supported && getAnalytics(app)),
}